import '@mobiscroll/react/dist/css/mobiscroll.min.css'

import { datadogRum } from '@datadog/browser-rum'
import { ThemeProvider } from '@mui/material'
import { StyledEngineProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { LicenseInfo } from '@mui/x-license-pro'
import { init } from 'commandbar'
import { initializeApp } from 'firebase/app'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { Routes } from './components'
import { getEnv, getFirebaseConfig } from './config/configs'
import {
  AuthProvider,
  NavVisibilityProvider,
  NotificationProvider,
  WindowSizeProvider
} from './providers'
import { store } from './redux/store'
import theme from './styles/theme'

// Yes, this license key can be seen by anyone who accesses the app. That caveat
// is acceptable to the vendor of MUI Data Grid Pro.
LicenseInfo.setLicenseKey(
  '7b56deb4ed33eaae6207080b07e97491Tz01OTg5NyxFPTE3MDgwNDM5OTMwNjIsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
)
const firebaseApp = initializeApp(getFirebaseConfig())

if (getEnv() !== 'development') {
  datadogRum.init({
    applicationId: '4b15cd64-4539-400d-b94b-0cd2ca3c7deb',
    clientToken: 'pub791d1a1db7a92758caf4460f500e741d',
    site: 'datadoghq.com',
    service: 'intune-front-end',
    env: getEnv(),
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [
      'https://intune-api.herokuapp.com/rest',
      (url) => url.startsWith('https://intune-api.herokuapp.com/rest'),
      'https://intune-api-qa.herokuapp.com/rest',
      (url) => url.startsWith('https://intune-api-qa.herokuapp.com/rest')
    ]
  })

  datadogRum.startSessionReplayRecording()
}
// Initialize Command Bar
init('56ed0845')

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthProvider firebaseApp={firebaseApp}>
        <NavVisibilityProvider>
          <WindowSizeProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <NotificationProvider>
                    <Routes />
                  </NotificationProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </LocalizationProvider>
          </WindowSizeProvider>
        </NavVisibilityProvider>
      </AuthProvider>
    </Provider>
    <ToastContainer />
  </BrowserRouter>
)
